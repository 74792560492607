<template>
  <div
    class="buy-crypto-tooltip"
    :class="wrapperClasses"
    data-t="buy-crypto-tooltip"
  >
    <StTooltip v-if="platform === 'desktop'" trigger="click">
      <template #activator>
        <div class="label">
          {{ t('payments.buyCrypto.tooltipHeader') }}
          <span class="tooltip-icon"> i </span>
        </div>
      </template>
      <div class="content">
        <p>
          {{ t('payments.buyCrypto.tooltipContentText') }}
        </p>
        <p v-if="!isHomePage">
          {{ t('payments.buyCrypto.tooltipContentText2') }}
        </p>
        <p v-if="!isHomePage">
          {{ t('payments.buyCrypto.tooltipContentText3') }}
        </p>
      </div>
    </StTooltip>
    <div v-else>
      <div class="label" @click="isDrawerOpened = true">
        {{ t('payments.buyCrypto.tooltipHeader') }}
        <span class="tooltip-icon"> i </span>
      </div>
      <StDrawer v-model="isDrawerOpened" :full-screen="false">
        <div class="mobile-title">
          {{ t('payments.buyCrypto.tooltipHeader') }}
          <StIcon
            name="cross-large"
            size="20"
            @click="isDrawerOpened = false"
          />
        </div>
        <div class="mobile-content">
          <p>
            {{ t('payments.buyCrypto.tooltipContentText') }}
          </p>
          <p v-if="!isHomePage">
            {{ t('payments.buyCrypto.tooltipContentText2') }}
          </p>
          <p v-if="!isHomePage">
            {{ t('payments.buyCrypto.tooltipContentText3') }}
          </p>
        </div>
      </StDrawer>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isHomePage?: boolean
}>()

const { t } = useI18n()
const { platform } = usePlatform()
const isDrawerOpened = ref(false)

const wrapperClasses = computed(() => ({
  mobile: platform.value === 'mobile',
  'home-page': props.isHomePage,
}))
</script>

<style scoped>
.buy-crypto-tooltip {
  cursor: help;
  display: flex;
}

.label {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.content {
  width: 520px;
  padding: var(--spacing-200);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.tooltip-icon {
  width: 16px;
  height: 16px;
  /* stylelint-disable */
  font-family: 'SF Pro Display';
  font-size: 10.667px;
  font-weight: 600;
  font-style: normal;
  line-height: 16px;
  color: var(--text-secondary, rgb(255 255 255 / 64%));
  text-align: center;

  background: var(--background-ghost-tertiary, rgb(255 255 255 / 16%));
  backdrop-filter: blur(9.999998092651367px);
  border-radius: 999px;
  /* stylelint-enable */
}

.home-page {
  .content {
    width: 417px;
  }

  .label {
    font: var(--mobile-caption-1-medium);
  }
}

.mobile-title {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-100) var(--spacing-200) 0;

  font: var(--mobile-title-2-semibold);
}

.mobile-content {
  padding: 0 var(--spacing-200) var(--spacing-200);
  font: var(--mobile-caption-1-medium);
  color: var(--text-tertiary);
}

.mobile {
  .tooltip-icon {
    /* stylelint-disable */
    width: 14px;
    height: 14px;

    font-family: 'SF Pro Display';
    font-size: 9.333px;
    font-weight: 600;
    font-style: normal;
    line-height: 14px; /* 150% */
    color: var(--text-secondary, rgb(255 255 255 / 64%));
    text-align: center;
    /* stylelint-enable */
  }
}
</style>
