import Decimal from '@st/decimal'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useTariffsStore } from '../stores/useTariffsStore'

export function useCalypso() {
  const { settings } = storeToRefs(useSettingsStore())
  const { limits } = storeToRefs(useTariffsStore())

  const isEnabled = computed(
    () => !!settings.value?.initCalypsoDepositIsAllowed,
  )

  const allowedNetworkIds = computed(
    () => settings.value?.allowedForCalypsoDepositNetworks ?? [],
  )

  const allowedCurrencyIds = computed(() =>
    Object.entries(settings.value?.currencySettings ?? {})
      .filter(
        ([_currencyId, currencySettings]) =>
          currencySettings.fiatDeposit.enabled,
      )
      .map(([currencyId]) => Number(currencyId)),
  )

  function getLimitsByCurrencyId(currencyId: number) {
    const calypsoSettings =
      settings.value?.currencySettings?.[currencyId]?.fiatDeposit

    return {
      min: calypsoSettings?.minAmount ?? '0',
      max: calypsoSettings?.maxAmount ?? '0',
    }
  }

  function getFastAmounts(currencyId: number, networkId: number) {
    const foundLimit = limits.value?.find(
      (limit) =>
        limit.currencyId === currencyId && limit.networkId === networkId,
    )

    if (!foundLimit) return []

    const minLimit = new Decimal(foundLimit?.minAmount)

    return [
      minLimit.mul(10).toString(),
      minLimit.mul(20).toString(),
      minLimit.mul(50).toString(),
    ]
  }

  return {
    isEnabled,
    allowedCurrencyIds,
    allowedNetworkIds,
    getLimitsByCurrencyId,
    getFastAmounts,
  }
}
